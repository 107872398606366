import { Injectable } from '@angular/core';
import { API_RESPONSE_STATUS } from '../utils/enum';
import { IResponse } from '../interfaces/iresponse';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _profile: BehaviorSubject<any | null> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private utils: UtilsService) {}

  get profile$(): Observable<IResponse<any>> {
    return this._profile.asObservable();
  }

  loginAdmin(data: any): Observable<IResponse<any>> {
    this.utils.RemoveToken();
    return this.http
      .post<IResponse<any>>(
        `${environment.baseUrl}administrator/security/login`,
        data
      )
      .pipe(
        map((res: IResponse<any>) => {
          if (
            res.status_code === API_RESPONSE_STATUS.SUCCESS &&
            res.data.token !== null
          ) {
            this.utils.SetToken(res.data.token);
          }
          return res;
        })
      );
  }

  getProfile(): Observable<any> {
    return this.http
      .get<IResponse<any>>(
        `${environment.baseUrl}administrator/security/profile`
      )
      .pipe(
        map((response) => {
          this._profile.next(response);
        })
      );
  }

  requestPasswordEmail(data: any): Observable<IResponse<any>> {
    return this.http
      .post<IResponse<any>>(
        `${environment.baseUrl}security/account/request-forgot-password`,
        data
      )
      .pipe(
        map((res: IResponse<any>) => {
          return res;
        })
      );
  }

  updatePassword(data: any): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(
      `${environment.baseUrl}security/account/update-password`,
      data
    );
  }

  updateMyPassword(data: any): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(
      `${environment.baseUrl}security/admin/profile/update-admin-password`,
      data
    );
  }
}
