<div class="flex h-screen bg-[#1D2424] overflow-y-hidden">
  <!-- Sidebar -->
  <div class="w-1/6">
    <!-- Sidebar content -->
    <div class="sm:overflow-y-scroll h-screen p-4">
      <div class="pl-3">
        <div class="">
          <img
            style="border-radius: 8px; margin-left: auto; margin-right: auto"
            class="h-16 w-30"
            src="/assets/images/ministere2.png"
            alt=""
          />
        </div>
        <!-- <div class="app-name text-center text-white text-lg uppercase font-mono">
          Enfutage
        </div> -->
      </div>

      <app-logged-admin-picture
        [user]="administrator"
      ></app-logged-admin-picture>

      <div
        class="links-container w-full flex flex-col gap-6 mt-3 items-start justify-start"
      >
        <ng-container *ngFor="let menu of MENU_LINKS; let i = index">
          <ng-container *ngIf="!menu.isActive">
            <div
              class="link-item flex items-center gap-2 w-full p-2 cursor-pointer"
              (click)="onRedirectTo(menu)"
              [id]="'dropdownDefaultButton' + i"
            >
              <img [src]="menu.icon" alt="item.title" />

              <div class="text-white">{{ menu.title }}</div>
            </div>
            <div
              id="dropdown"
              class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul
                class="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              ></ul>
            </div>
          </ng-container>
          <ng-container *ngIf="menu.isActive">
            <div
              class="link-item flex items-center gap-2 bg-[#353233] w-full p-2 cursor-pointer rounded-md"
              (click)="onRedirectTo(menu)"
            >
              <div class="p-2 bg-white rounded-md flex items-center">
                <img [src]="menu.icon_active" alt="item.title" />
              </div>

              <div class="text-white">{{ menu.title }}</div>
            </div></ng-container
          >
        </ng-container>
      </div>

      <!-- <div class="mt-10">
        <img style="border-radius: 8px; margin-left: auto; margin-right: auto;" class="h-16 w-40  opacity-35" src="/assets/images/ministere2.png" alt="">
      </div> -->
      <!-- <button
        class="bg-[#15700d] p-3 rounded-md w-full mt-7 flex justify-center items-center gap-1 cursor-not-allowed"
      >
        <span
          nz-icon
          nzType="setting"
          nzTheme="outline"
          class="text-white"
        ></span>
        <span class="text-white text-md">Paramètre</span>
      </button> -->
    </div>
  </div>

  <!-- Main content -->
  <div class="flex-1 bg-[#1D2424] p-4">
    <div class="overflow-y-scroll h-screen bg-white p-4 rounded-md">
      <ng-container *ngIf="isPageChange">
        <div class="w-full items-center content-center h-full flex flex-col">
          <app-page-loader></app-page-loader>
        </div>
      </ng-container>

      <ng-container *ngIf="!isPageChange">
        <div class="mb-16">
          <app-page-header></app-page-header>
        </div>
        <router-outlet *ngIf="true"></router-outlet>
      </ng-container>
    </div>
  </div>
</div>
