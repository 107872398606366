import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDropdown } from '../interfaces/idropdown';
import { IPaginate } from '../interfaces/ipaginate';
import { IResponse } from '../interfaces/iresponse';
import { API_RESPONSE_STATUS } from '../utils/enum';

@Injectable({
  providedIn: 'root',
})
export class BaseService<T extends any> {
  private _dropdown$: BehaviorSubject<IDropdown[]> = new BehaviorSubject<
    IDropdown[]
  >([]);
  private _allData$: BehaviorSubject<IPaginate<T>> = new BehaviorSubject<
    IPaginate<T>
  >({
    items: [],
    page: 0,
    pageSize: 0,
    total: 0,
    length: 0,
    errors: [],
    current_page: 1,
    per_page: 10,
    next_page: 1,
    previous_page: null,
    current_search: '',
  });

  private _getOne$: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(
    null
  );

  constructor(
    private _http: HttpClient,
    @Inject(String) private _uri: string
  ) {}

  // @ts-ignore
  get allData$(): Observable<IPaginate<T>> {
    return this._allData$.asObservable();
  }

  set allData$(value: IPaginate<T>) {
    this._allData$.next(value);
  }

  // @ts-ignore
  get getOne$(): Observable<T | null> {
    return this._getOne$.asObservable();
  }

  set getOne$(value: T) {
    this._getOne$.next(value);
  }

  // @ts-ignore
  get dropdown$(): Observable<IDropdown[]> {
    return this._dropdown$.asObservable();
  }

  set dropdown$(value: IDropdown[]) {
    this._dropdown$.next(value);
  }

  /**
   *
   * @param data
   */
  public create(data: any): Observable<IResponse<any>> {
    return this._http.post<IResponse<any>>(
      `${environment.baseUrl}${this._uri}/store`,
      data
    );
  }

  /**
   * Avoir la liste de tous les éléments pour un dropdown
   */
  public dropDown(): Observable<IResponse<IDropdown[]>> {
    return this._http
      .get<IResponse<IDropdown[]>>(
        `${environment.baseUrl}${this._uri}/drop-down`
      )
      .pipe();
  }

  /**
   * Modifié une donnée
   *
   * @param elementId
   * @param data
   */
  public editOne(
    elementId: string | number,
    data: any
  ): Observable<IResponse<T>> {
    return this._http.put<IResponse<T>>(
      `${environment.baseUrl}${this._uri}/update/${elementId}`,
      data
    );
  }

  public toggleRecordStatus(
    elementId: string | number,
    mode: any
  ): Observable<IResponse<T>> {
    return this._http.put<IResponse<T>>(
      `${environment.baseUrl}/${this._uri}/${mode}/${elementId}`,
      {}
    );
  }

  /**
   * Supprimé une donnée de la base de donné
   *
   * @returns {Observable<<T>>}
   * @param elementId
   */
  public deleteOne(elementId: string | number): Observable<IResponse<T>> {
    return this._http.delete<IResponse<T>>(
      `${environment.baseUrl}${this._uri}/delete/${elementId}`
    );
  }
  public softDeleteOne(elementId: string | number): Observable<IResponse<T>> {
    return this._http.delete<IResponse<T>>(
      `${environment.baseUrl}/${this._uri}/soft/${elementId}`
    );
  }

  /**
   * Changé le statut d'une donnée
   *
   * @param elementId
   * @param status
   */
  public toggleStatus(
    elementId: string | number,
    status: any
  ): Observable<IResponse<T>> {
    return this._http.put<IResponse<T>>(
      `${environment.baseUrl}/${this._uri}/toggle/${elementId}/${status}`,
      null
    );
  }

  /**
   * get image of one data
   * @param publicId
   */
  public getRessource(publicId: string): Observable<Object> {
    return this._http.get(
      `${environment.baseUrl}/commons/resource/${publicId}`
    );
  }

  /**
   * Récupérer un élément par son id
   *
   * @returns {Observable<<T>>}
   * @param elementId
   */
  protected getOne(elementId: string | number): Observable<IResponse<T>> {
    return this._http
      .get<IResponse<T>>(`${environment.baseUrl}${this._uri}/${elementId}`)
      .pipe(
        map((value: IResponse<T>) => {
          if (value.status_code === API_RESPONSE_STATUS.SUCCESS && value.data) {
            this.getOne$ = value.data;
          }
          return value;
        })
      );
  }

  /**
   * La liste de tous les éléments
   *
   * @param {number} page
   * @param {number} limit
   * @param search
   * @param order
   * @returns {Observable<<IPaginate<T>>>}
   */

  protected getAll(
    page: number = 1,
    limit: number = 100,
    search: string = '',
    order: string = ''
  ): Observable<IResponse<IPaginate<any>>> {
    return this._http
      .get<IResponse<IPaginate<T>>>(
        `${environment.baseUrl}${this._uri}/paginate?page=${page}&limit=${limit}&search=${search}&orderBy=createdAt&orderType=${order}`
      )
      .pipe(
        map((value: IResponse<IPaginate<T>>) => {
          if (
            value.status_code === API_RESPONSE_STATUS.SUCCESS ||
            (value.status_code === API_RESPONSE_STATUS.SUCCESS_2000 &&
              value.data.items)
          ) {
            this.allData$ = value.data;
          } else {
          }
          return value;
        })
      );
  }
}
