import { CanActivateFn, Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { inject } from '@angular/core';
import { routeMap } from '../utils/routeMap';

export const authGuard: CanActivateFn = (route, state) => {
  const utilsService: UtilsService = inject(UtilsService);
  const router: Router = inject(Router);
  if (utilsService.hasToken()) {
    return true;
  }
  router.navigate([routeMap.auth.connexion]);
  return false;
};
