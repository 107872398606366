<div class="admin-user-container flex flex-col items-center gap-0 mb-6 mt-6">
  <ng-container *ngIf="haveImage">
    <div
      class="user-image"
      [ngStyle]="{
        'background-image': 'url(' + pictureUrl + ')'
      }"
    ></div>
  </ng-container>
  <ng-container *ngIf="!haveImage">
    <div
      class="initial w-20 h-20 p-4 bg-white shadow-md rounded-full flex justify-center items-center font-bold"
    >
      {{ userInitial }}
    </div>
  </ng-container>

  <div
    class="user-name-section mt-3 text-white text-bold text-center font-bold"
  >
    <div>
      {{
        user.type_direction === "DEPARTMENT"
          ? "Direction départementale"
          : "Direction régionale"
      }}
    </div>
    <ng-container *ngIf="user.city">
      <div>({{ user?.city?.name }})</div>
    </ng-container>
  </div>
</div>

<!-- <div
  class="logo-container flex flex-col justify-center gap-2 items-center mt-5"
>
  <div
    class="avatar w-16 h-16 bg-cover bg-no-repeat bg-center rounded-full"
    [ngStyle]="{
      'background-image':
        'url(' +
        pictureUrl +
        administrator.type_direction.charAt(0) +
        '' +
        administrator?.city?.name.charAt(0) +
        '' +
        ')'
    }"
  ></div>
  <div class="user text-white">{{ administrator.name }}</div>
  <span class="text-xs text-white">{{ administrator.email }}</span>
</div> -->
