import { Injectable } from '@angular/core';
import { routeMap } from '../utils/routeMap';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { LOCAL_STORAGE_KEY } from '../utils/constantes';
import { Decrypt, Encrypt } from '../utils/encode';
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /**
   * Account Object
   * @private
   */
  private _compteData$: BehaviorSubject<any> = new BehaviorSubject(null);

  /**************************
   * AUTHENTICATION SECTION *
   **************************/
  /**
   * Token value
   * @private
   */
  private _token: string | null = null;
  /**
   * Page Title
   * @private
   */
  private _pageTitle$: BehaviorSubject<string> = new BehaviorSubject('Accueil');

  constructor(private router: Router) {}

  public get compteData$(): BehaviorSubject<any | null> {
    return this._compteData$;
  }

  public set compteData$(value: any) {
    this._compteData$.next(value);
  }

  get token(): string | null {
    return this._token;
  }

  set token(value: string | null) {
    this._token = value;
  }

  public get pageTitle$(): BehaviorSubject<string> {
    return this._pageTitle$;
  }

  public set pageTitle$(value: any) {
    this._pageTitle$.next(value);
  }

  /**
   * Get Token
   * @constructor
   */
  public GetToken(): string | null {
    return Decrypt(localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN));
  }

  /**
   * Set Token
   * @param token
   * @constructor
   */
  public SetToken(token: string): void {
    localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, Encrypt(token));
  }

  /**
   * Remove token
   * @constructor
   */
  public RemoveToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
  }

  /**************
   * PAGE TITLE *
   **************/

  /**
   * Disconnect an user
   * @constructor
   */
  public Disconnect(): void {
    this.RemoveToken();
    this.token = null;
    this.router.navigate([routeMap.auth.connexion]);
  }

  /**
   * Disconnect a user with confirmation
   * @constructor
   */
  public LogOut(): void {}

  public hasToken() {
    return this.GetToken() !== null;
  }
}
