<form [formGroup]="form">
  <nz-row nzGutter="24" class="p-2">
    <nz-col nzSm="24" nzMd="24">
      <nz-form-item>
        <nz-form-label [nzXs]="24" nzFor="name" nzRequired class="text-left">
          Nouveau Mot de passe
        </nz-form-label>
        <nz-form-control nzErrorTip="Le Mot de passe  est requis">
          <nz-input-group
            class="inputs"
            nzPrefixIcon="lock"
            [nzSuffix]="passwordSuffix"
          >
            <input
              [type]="passwordFieldType"
              formControlName="new_password"
              nz-input
              placeholder="******************************"
            />
          </nz-input-group> </nz-form-control
      ></nz-form-item>
    </nz-col>

    <nz-col nzSm="24" nzMd="24">
      <nz-form-item>
        <nz-form-label [nzXs]="24" nzFor="name" nzRequired class="text-left">
          Confirmer le mot de passe
        </nz-form-label>
        <nz-form-control
          nzErrorTip="Le mot de passe de confirmation est requis"
        >
          <nz-input-group
            class="inputs"
            nzPrefixIcon="lock"
            [nzSuffix]="passwordSuffix"
          >
            <input
              [type]="passwordFieldType"
              nz-input
              formControlName="confirm_password"
          /></nz-input-group> </nz-form-control
      ></nz-form-item>
    </nz-col>
  </nz-row>

  <div class="footer bg-gray-100 flex items-center justify-between px-2 py-4">
    <button nz-button (click)="close()">Annuler</button>
    <button
      nz-button
      nzType="primary"
      [nzLoading]="isLoading"
      [disabled]="form.invalid || isLoading"
      (click)="onSubmit()"
    >
      Confirmer
    </button>
  </div>
</form>
<ng-template #passwordSuffix>
  <span
    nz-icon
    nzType="eye"
    nzTheme="outline"
    *ngIf="passwordFieldType === 'password'"
    (click)="togglePasswordVisibility()"
    class="cursor-pointer"
  ></span>

  <span
    nz-icon
    nzType="eye-invisible"
    nzTheme="outline"
    class="cursor-pointer"
    *ngIf="passwordFieldType === 'text'"
    (click)="togglePasswordVisibility()"
  ></span>
</ng-template>
