import { Injectable } from '@angular/core';
import { DeleteConfirmationModalComponent } from '../shared/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { Subject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AutorisationModalComponent } from '../pages/applicants/components/autorisation-modal/autorisation-modal.component';
import { EditUserDataModalComponent } from '../pages/profil/components/edit-user-data-modal/edit-user-data-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private _modalService: NzModalService) {}

  // openInformationModal(title: string, description?: string) {
  //   this._modalService.create<UncompleteFormModalComponent, any>({
  //     nzContent: UncompleteFormModalComponent,
  //     nzWidth: 500,

  //     nzFooter: null,
  //     nzMaskClosable: false,
  //     nzTitle: title,
  //     nzClosable: true,
  //     nzClassName: 'no-radius',
  //     nzData: {
  //       description,
  //     },
  //     nzBodyStyle: { padding: '0px' },
  //   });
  // }

  openDeleteModal(
    title: string = 'Suppression',
    description: string = 'Vous allez supprimer cette donnée. Continuer ?'
  ) {
    const modalRef = this._modalService.create<
      DeleteConfirmationModalComponent,
      any
    >({
      nzContent: DeleteConfirmationModalComponent,
      nzWidth: 500,

      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: false,
      nzClassName: 'no-radius',
      nzData: {
        title: title,
        description: description,
      },
      nzBodyStyle: { padding: '0px' },
    });

    const resultSubject = new Subject<any>();
    modalRef.afterClose.subscribe((result: any) => {
      resultSubject.next(result);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  createAutorisation(
    title: string = "Enregistrement d'une nouvelle autorisation"
  ) {
    const modalRef = this._modalService.create<AutorisationModalComponent, any>(
      {
        nzContent: AutorisationModalComponent,
        nzWidth: 500,
        nzTitle: title,
        nzFooter: null,
        nzMaskClosable: false,
        nzClosable: false,
        nzClassName: 'no-radius',
        nzBodyStyle: { padding: '0px' },
      }
    );

    const resultSubject = new Subject<any>();
    modalRef.afterClose.subscribe((result: any) => {
      resultSubject.next(result);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  editProfileData(title: string = 'Editer les information de profile') {
    const modalRef = this._modalService.create<EditUserDataModalComponent, any>(
      {
        nzContent: EditUserDataModalComponent,
        nzWidth: 500,
        nzTitle: title,
        nzFooter: null,
        nzMaskClosable: false,
        nzClosable: false,
        nzClassName: 'no-radius',
        nzBodyStyle: { padding: '0px' },
      }
    );

    const resultSubject = new Subject<any>();
    modalRef.afterClose.subscribe((result: any) => {
      resultSubject.next(result);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }
}
