<div>
  <form
    class="p-2"
    [nzLabelWrap]="true"
    nz-form
    [formGroup]="form"
    nzLabelAlign="left"
    nzLayout="vertical"
  >
    <nz-row nzGutter="24">
      <nz-col nzSm="24" nzMd="24">
        <nz-form-item>
          <nz-form-label [nzXs]="24" nzFor="name" nzRequired>
            Date d'attribution
          </nz-form-label>
          <nz-form-control nzErrorTip="Le champ attribution_date est requis">
            <input type="date" formControlName="attribution_date" nz-input />
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSm="24" nzMd="24">
        <nz-form-item>
          <nz-form-label [nzXs]="24" nzFor="name" nzRequired>
            La validité en mois(durée)
          </nz-form-label>
          <nz-form-control nzErrorTip="La validité en mois est requise">
            <nz-input-number
              formControlName="month_validity"
              [nzMin]="1"
              nzPlaceHolder="Exp : 12"
              [nzStep]="1"
              class="w-full"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col nzSm="24" nzMd="24">
        <nz-form-item>
          <nz-form-label [nzXs]="24" nzFor="name" nzRequired>
            Destination
          </nz-form-label>
          <nz-form-control>
            <nz-form-control nzErrorTip="">
              <input type="text" formControlName="destination" nz-input />
            </nz-form-control>
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col nzSm="24" nzMd="12">
        <nz-form-item>
          <nz-form-label [nzXs]="24" nzFor="name" nzRequired>
            Quantité max super (litres)
          </nz-form-label>
          <nz-form-control>
            <nz-form-control nzErrorTip="La quantité en litre est requise">
              <nz-input-number
                formControlName="liter_quantity"
                [nzMin]="1"
                nzPlaceHolder="Exp : 12"
                [nzStep]="1"
                class="w-full"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col nzSm="24" nzMd="12">
        <nz-form-item>
          <nz-form-label [nzXs]="24" nzFor="name" nzRequired>
            Quantité max gasoil (litres)
          </nz-form-label>
          <nz-form-control nzErrorTip="La valeur est requise">
            <nz-input-number
              formControlName="max_gazoil_quantity"
              [nzMin]="1"
              nzPlaceHolder="Exp : 12"
              [nzStep]="1"
              class="w-full"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </nz-col>

      <nz-col nzSm="24" nzMd="24">
        <nz-form-item>
          <nz-form-label [nzXs]="24" nzFor="name" nzRequired>
            Choisir la/les station(s) de départ
          </nz-form-label>
          <nz-form-control>
            <nz-select
              [nzAllowClear]="true"
              nzId="applicants"
              formControlName="station_id"
              class="weni-input-field-form"
              nzPlaceHolder="Sélectionner la station"
              nzSize="large"
              nzMode="multiple"
            >
              <nz-option
                *ngFor="let item of stations"
                [nzLabel]="item.concatenedField"
                [nzValue]="item.id"
              ></nz-option>
            </nz-select> </nz-form-control
        ></nz-form-item>
      </nz-col>
    </nz-row>
  </form>

  <div class="flex items-center justify-between w-full bg-gray-100 p-2">
    <button nz-button (click)="onCancel()">Annuler</button>
    <button
      nz-button
      nzType="primary"
      [disabled]="form.invalid"
      (click)="onSubmit()"
    >
      Confirmer
    </button>
  </div>
</div>
