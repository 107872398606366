import { Component, Input, OnInit } from '@angular/core';
import { IAdministrator } from 'src/app/interfaces/iadministrator';
import { environment } from 'src/environments/environment';
import { CommonModule, NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'app-logged-admin-picture',
  templateUrl: './logged-admin-picture.component.html',
  styleUrls: ['./logged-admin-picture.component.scss'],
  standalone: true,
  imports: [CommonModule, NgIf, NgStyle],
})
export class LoggedAdminPictureComponent implements OnInit {
  @Input('user') user!: IAdministrator;

  pictureUrl: string | any = 'https://ui-avatars.com/api/?name=';
  haveImage: boolean = false;
  userInitial: string = '';

  constructor() {}

  ngOnInit(): void {
    this.userInitial = this.getFirstLetters(
      this.user.type_direction === 'DEPARTMENT'
        ? 'D' + ' D ' + this.user.city.name
        : 'D ' + 'R ' + this.user.city.name
    );

    if (this.user.avatar) {
      this.haveImage = true;
      this.pictureUrl = environment.storageUrl + '' + this.user.avatar.path;
    }
  }

  getFirstLetters(str: string) {
    // Divisez la chaîne en mots à l'aide de l'espace comme séparateur
    const words = str.split(' ');

    // Initialisez une chaîne vide pour stocker les premières lettres
    let firstLetters = '';

    // Bouclez sur chaque mot
    for (const word of words) {
      // Ajoutez la première lettre du mot à la chaîne firstLetters
      firstLetters += word.charAt(0);
    }

    // Retournez la chaîne firstLetters
    return firstLetters;
  }
}
