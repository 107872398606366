<div class="p-4">
  <div class="flex flex-col gap-1 justify-center items-center">
    <div
      class="bg-red-200 rounded-full w-10 h-10 flex items-center justify-center p-2"
    >
      <span
        nz-icon
        nzType="warning"
        nzTheme="outline"
        class="text-red-700"
      ></span>
    </div>
    <div class="font-bold text-lg">Êtes vous sûr ?</div>
  </div>

  <p class="text-center text-gray-600 mb-3">{{ description }}</p>

  <div class="flex flex-col gap-2">
    <button nz-button (click)="onCancel()" class="w-full">Annuler</button>
    <button
      nz-button
      nzType="primary"
      (click)="onSubmit()"
      class="w-full bg-red-700 border-red-700 text-white"
    >
      Confirmer
    </button>
  </div>
</div>
