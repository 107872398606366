import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { IDropdown } from 'src/app/interfaces/idropdown';
import { AreaService } from 'src/app/services/area.service';
import { StationService } from 'src/app/services/station.service';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgFor, NgIf } from '@angular/common';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

@Component({
  selector: 'app-autorisation-modal',
  templateUrl: './autorisation-modal.component.html',
  styleUrls: ['./autorisation-modal.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    FormsModule,
    NgFor,
    NgIf,
    NzButtonModule,
    NzWaveModule,
    NzInputNumberModule,
  ],
})
export class AutorisationModalComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean = false;
  stations: IDropdown[] = [];
  areas: IDropdown[] = [];

  constructor(
    private modalRef: NzModalRef,
    private fb: FormBuilder,
    private _notificationService: NzNotificationService,
    private _stationService: StationService,
    private _areaService: AreaService
  ) {
    this.form = this.fb.group({
      attribution_date: new FormControl('', [Validators.required]),
      month_validity: new FormControl('', [Validators.required]),
      liter_quantity: new FormControl('', [Validators.required]),
      max_gazoil_quantity: new FormControl('', [Validators.required]),
      destination: new FormControl('', [Validators.required]),
      station_id: new FormControl(null, [Validators.required]),
    });
  }
  ngOnInit(): void {
    this.getStations();
    this.getAreas();
  }

  getStations() {
    this._stationService.dropDown().subscribe((response) => {
      this.stations = response.data;
    });
  }
  getAreas() {
    this._areaService.dropDown().subscribe((response) => {
      this.areas = response.data;
    });
  }

  onCancel() {
    this.modalRef.close();
  }
  onSubmit() {
    const formRaw = this.form.getRawValue();
    this.isLoading = true;
    if (!formRaw.station_id && !formRaw.supplier_area_id) {
      this.isLoading = false;
      this._notificationService.error(
        'Erreur',
        'Veuillez choisir une zone ou une station'
      );
      return;
    }

    this.modalRef.close({
      confirmed: true,
      data: formRaw,
    });
  }
}
