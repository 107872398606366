import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgIf } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NzButtonModule, NzWaveModule, NzIconModule],
})
export class DeleteConfirmationModalComponent implements OnInit {
  description: string = '';

  constructor(private modalRef: NzModalRef) {}
  ngOnInit(): void {
    this.description = this.modalRef.getConfig().nzData.description;
  }

  onCancel() {
    this.modalRef.close();
  }
  onSubmit() {
    this.modalRef.close({
      confirmed: true,
    });
  }
}
