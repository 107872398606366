import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-user-data-modal',
  templateUrl: './edit-user-data-modal.component.html',
  styleUrls: ['./edit-user-data-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzGridModule,
    NzInputModule,
    NzButtonModule,
    NzFormModule,
    ReactiveFormsModule,
    NzIconModule,
  ],
})
export class EditUserDataModalComponent {
  passwordFieldType: string = 'text';

  form: FormGroup;
  isLoading: boolean = false;
  constructor(
    private _fb: FormBuilder,
    private modalRef: NzModalRef,
    private msg: NzMessageService
  ) {
    this.form = this._fb.group({
      new_password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required]),
    });
  }

  togglePasswordVisibility() {
    if (this.passwordFieldType === 'password') {
      this.passwordFieldType = 'text';
    } else {
      this.passwordFieldType = 'password';
    }
  }

  close() {
    this.modalRef.close();
  }

  onSubmit() {
    const formRaw = this.form.getRawValue();
    this.isLoading = true;
    if (formRaw.new_password != formRaw.confirm_password) {
      this.msg.error('Les mots de passes ne correspondent pas');
      this.isLoading = false;
      return;
    }

    this.modalRef.close({
      data: {
        password: formRaw.new_password,
      },
    });
  }
}
