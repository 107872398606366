export const KEY_LANG = 'lang';
export const APP_NAME = 'WENI';
export const KEY_MAIL_USER = `KEY_MAIL_${APP_NAME}`;
export const TOKEN_USER_LOGIN = `TOKEN_${APP_NAME}`;
export const EMPTY_DATA: string = 'Aucune donnée disponible.';

export const KEY_SESSION_USER = `Va4PZErt2yNqW0hiGOBA5YAaxncZxsU_${APP_NAME}`;
export const KEY_SECRET_ENCRYPT = `Y9Kj3jaIKJNs4arqOH4rsaTnvmgjc04_${APP_NAME}`;
export const KEY_SECRET_ENCRYPT_DATE_LOGIN = `XbJVLx72fzpurcjU6YxETvIb2UERY37_${APP_NAME}`;

export enum ASSETS_IMAGES {
  EMPTY_DATA = 'assets/images/svg/empty.svg',
}

export enum MESSAGE {
  DATA_NOT_FOUND = 'Donnée non trouvée',
  DATA_NOT_AVAILABLE = 'Donnée non disponible',
  CREATE_SUCCESS = 'Enregistrement effectué avec succès.',
  CREATE_ERROR = "Échec de l'enregistrement.",
  EDIT_SUCCESS = 'Modification éffectuer avec succès.',
  DELETE_SUCCESS = 'Un élément supprimé.',
  DELETE_ERROR = 'Échec de la suppression.',
  EDIT_ERROR = 'Échec de la modification.',
  EMPTY_DATA = 'Aucune donnée disponible.',
  SERVER_OUT = "Service non disponible pour l'instant !",
  REQUIRED_FIELD = 'Ce champ est obligatoire.',
  INVALID_FIELD = 'Ce champ est invalide.',
  EMAIL_EXISTE = 'Erreur le mail existe déjà.',
  PASSWORD_INVALID = 'Mot de passe invalide',
  USER_NOT_FOUND = 'Aucun utilisateur trouvé',
  DATA_EXIST = 'Cet élément existe déjà',
  ACCOUNT_NOT_ENABLED = "Ce compte n'est pas activé",
  INVALID_FORM = 'Le formulaire est invalide',
  CONFIRM_DELETE = 'Voulez-vous supprimer cet enregistrement ?',
  CONFIRM_ACTION = 'Confirmez-vous cette action ? ',
  CONFIRM_DELETE_ALL = 'Voulez-vous supprimer tout les enregistrements ?',
  VALIDATE_ACCOUNT_SUCCESS = 'Votre compte a été validé ',
  UNAUTHORIZED_PERMISSION = 'Permission non autorisée pour cette action. ',
  ERROR_SEND_OTP = 'Code OTP incorrect. Veuillez réessayer',
  ERROR_SEND_OTP_OLD = "Échec d'envoi du code de confirmation, Vérifier vos informations",
  SUCCESS_SEND_OTP_MAIL = 'Entrer le code reçu sur votre adresse mail enregistré',
  SUCCESS_SEND_OTP_PHONE = 'Entrer le code reçu sur votre numéro de téléphone enregistré',
  NOT_AVAILABLE_FUNCTION = 'Désolé, cette fonctionnalité est en cours de réalisation, celle-ci sera disponible dans les versions avenir.',
  NOT_AVAILABLE_CERTIFICAT_FOR_ADD_PROGRAM = "Certificat non valide. L'utilisateur ne peut donc pas participer au programme",
}

export enum FORMATE_DATE_TYPE {
  ddMMyyyy = 'dd-MM-yyyy',
  ddMMyyyyHHmmss = 'dd-MM-yyyy HH:mm:ss',
  HHmmss = 'HH:mm:ss',
  HHmm = 'HH:mm',
  yyyyMMdd = 'yyyy-MM-dd',
  yyyyMMddHHmmss = 'yyyy-MM-dd HH:mm:ss',
}

export enum GENDER_USER {
  Femme = 'F',
  Homme = 'M',
}

export enum CIVILITY {
  Monsieur = 'Monsieur',
  Madame = 'Madame',
  Mademoiselle = 'Mademoiselle',
}

export const CIVILITIES_ARRAY = [
  { label: CIVILITY.Monsieur, value: CIVILITY.Monsieur },
  { label: CIVILITY.Madame, value: CIVILITY.Madame },
  { label: CIVILITY.Mademoiselle, value: CIVILITY.Mademoiselle },
];

//  "jeu. 20 déc. 12, 03:00:00"
export const FORMATE_DATE_SHORT_WITH_TIME: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

// "jeu. 20 déc. 12"
export const FORMATE_DATE_SHORT_WITHOUT_TIME: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: '2-digit',
};

// "20 déc. 12,  03:00 "
export const FORMATE_DATE_SHORT_TIME_SHORT: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

export const initialPagination = { page: 1, pageSize: 10, total: 0, items: [] };

// "jeudi 20 décembre 2012
export const FORMATE_DATE_LONG_WITHOUT_TIME: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

export const LANGUE_DATE = undefined;

export enum LOCAL_STORAGE_KEY {
  COOKIE_CHOICES = 'H5132#HO!YUY5s_IRgql8E"[3E?e"\\}',
  TOKEN = '\\UwV5diIVuqEJpuA#8_!DpX2?GNoqnE',
  ADMIN_TOKEN = 'PudQF68MbB!6oPD!Hy._6Zqa:3afKdI',
}
