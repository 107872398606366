import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { IResponse } from '../interfaces/iresponse';
import { IPaginate } from '../interfaces/ipaginate';
import { MESSAGE } from '../utils/constantes';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class StationService extends BaseService<any> {
  private _station: BehaviorSubject<any | null> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    super(http, 'stations');
  }

  override getAll(
    page: number = 1,
    limit: number = 10,
    search: string = '',
    order: string = 'desc'
  ): Observable<IResponse<IPaginate<any>>> {
    return super.getAll(page, limit, search, order).pipe(
      catchError((err, caught) => {
        return throwError(err);
      })
    );
  }

  override getOne(id: string): Observable<IResponse<any>> {
    return super.getOne(id).pipe(
      tap((response) => {
        this._station.next(response.data);
      })
    );
  }

  get station$(): Observable<any> {
    return this._station.asObservable();
  }
}
