export const routeMap = {
  auth: {
    connexion: 'auth/connexion',
    mdpMail: 'auth/forgot-password',
    mdpCode: 'auth/verification-code',
    mdpReset: 'auth/reset-password',
  },

  dashboard: 'dashboard',

  authorization: {
    create: 'authorization-granted/create',
    list: 'authorization-granted/list',
    listGranted: 'authorization-granted/expired',
    show: 'authorization-granted/show',
    showEntreprise: 'authorization-granted/show-entreprise',
    editEntreprise: 'authorization-granted/edit-entreprise',
    edit: 'authorization-granted/edit',

    editPDF: {
      entreprise: 'authorization-granted/edit-pdf-entreprise',
      particular: 'authorization-granted/edit-pdf-particular',
    },
  },

  applicants_types: {
    list: 'applicants-types/list',
    edit: 'applicants-types/edit',
    create: 'applicants-types/create',
  },

  //Demandeur
  applicants: {
    list: 'applicants/list',
    edit: 'applicants/edit',
    editEntreprise: 'applicants/edit-entreprise',
    showEntreprise: 'applicants/show-entreprise',

    create: 'applicants/create',
    show: 'applicants/show',
  },
  //administratuers
  admins: {
    list: 'admins/list',
    create: 'admins/create',
    edit: 'admins/edit',
    show: 'admins/show',
  },
  referential: {
    home: 'referential',
    listStation: 'referential/list',

    supply: {
      list: '/referential/supply/list',
      create: '/referential/supply/create',
      edit: '/referential/supply/edit',
      show: '/referential/supply/show',
    },

    marketeur: {
      list: '/referential/marketers/list',
      create: '/referential/marketers/create',
      edit: '/referential/marketers/edit',
      show: '/referential/marketers/show',
    },
    stations: {
      list: '/referential/stations/list',
      create: '/referential/stations/create',
      edit: '/referential/stations/edit',
      details: '/referential/stations/show',
    },
  },

  histories: 'histories',
  adminProfil: 'admin-profil',
};
