import { Component, Input } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalService } from 'src/app/services/modal.service';
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [NzIconModule, NzBreadCrumbModule],
})
export class PageHeaderComponent {
  @Input('title') title: string = '';
  constructor(
    private _service: UtilsService,
    private modalService: ModalService
  ) {}

  onLogout() {
    this.modalService
      .openDeleteModal(
        'Déconnexion',
        'Vous allez vous déconnecter de votre compte'
      )
      .subscribe((res) => {
        if (res.confirmed) {
          this._service.Disconnect();
        }
      });
  }
}
