import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { authGuard } from './guards/auth.guard';
import { loggedAdminResolver } from './resolvers/logged-admin.resolver';
import { noAuthGuard } from './no-auth.guard';

export default [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    canActivate: [noAuthGuard],

    loadChildren: () =>
      import('./pages/auth/auth.module').then((con) => con.AuthModule),
  },
  {
    path: 'qr-code-document',
    loadChildren: () => import('./pages/qr-code-document/routes'),
  },

  // {
  //   path: 'authentification',
  //   loadChildren: () =>
  //     import('./pages/authentification/authentification.module').then((con) => con.AuthentificationModule),
  // },

  {
    path: '',
    component: AdminLayoutComponent,

    canActivate: [authGuard],
    data: {
      breadcrumb: 'Administrateurs',
    },
    resolve: { data: loggedAdminResolver },
    children: [
      {
        path: 'dashboard',
        data: {
          breadcrumb: 'Tableau de bord',
        },
        loadComponent: () =>
          import(
            './pages/dashboard/dashboard-home/dashboard-home.component'
          ).then((da) => da.DashboardHomeComponent),
      },

      {
        path: 'authorization-granted',
        data: {
          breadcrumb: 'Autorisations enregistrées',
        },
        loadChildren: () => import('./pages/authorization-granted/routes'),
      },

      {
        path: 'applicants-types',
        data: {
          breadcrumb: 'Types de demandeurs',
        },
        loadChildren: () => import('./pages/applicant-type/routes'),
      },
      {
        path: 'applicants',
        data: {
          breadcrumb: 'Demandeurs',
        },
        loadChildren: () => import('./pages/applicants/routes'),
      },
      {
        path: 'admins',
        data: {
          breadcrumb: 'Administrateur',
        },
        loadChildren: () => import('./pages/administrators/routes'),
      },
      {
        path: 'referential',
        data: {
          breadcrumb: 'Référentiel ',
        },
        loadChildren: () => import('./pages/referential/routes'),
      },

      {
        path: 'histories',
        data: {
          breadcrumb: 'Historiques ',
        },
        loadChildren: () =>
          import('./pages/histories/histories.module').then(
            (hi) => hi.HistoriesModule
          ),
      },
      {
        path: 'admin-profil',
        data: {
          breadcrumb: 'Profil-administrateur ',
        },
        loadComponent: () =>
          import('./pages/profil/details-profil/details-profil.component').then(
            (module) => module.DetailsProfilComponent
          ),
      },
    ],
  },
] as Routes;
