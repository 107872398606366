import { Component } from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { IAdministrator } from 'src/app/interfaces/iadministrator';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { routeMap } from 'src/app/utils/routeMap';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { PageLoaderComponent } from 'src/app/shared/page-loader/page-loader.component';
import { PageHeaderComponent } from 'src/app/shared/page-header/page-header.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { LoggedAdminPictureComponent } from 'src/app/shared/ui/logged-admin-picture/logged-admin-picture.component';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    CommonModule,
    NgIf,
    RouterOutlet,
    PageLoaderComponent,
    PageHeaderComponent,
    NzIconModule,
    LoggedAdminPictureComponent,
  ],
})
export class AdminLayoutComponent {
  parameterIcon: string = 'mingcute_settings-7-line.svg';
  MENU_LINKS = [
    {
      id: 1,
      title: 'Tableau de bord',
      icon_active: '/assets/images/dashboard/icons_active/svg/home.svg',
      icon: '/assets/images/dashboard/icons_off/svg/home.svg',
      isDropdown: false,
      isActive: true,
      link: routeMap.dashboard,
      isOpen: false,
      subMenuItems: [],
    },
    {
      id: 2,
      title: 'Autorisations',
      icon_active:
        '/assets/images/dashboard/icons_active/svg/ri_list-settings-fill.svg',
      icon: '/assets/images/dashboard/icons_off/svg/ri_list-settings-fill.svg',
      isDropdown: true,
      isActive: false,
      isOpen: false,
      link: routeMap.authorization.list,
      subMenuItems: [],
    },

    {
      id: 3,
      title: 'Demandeurs',
      icon_active:
        '/assets/images/dashboard/icons_active/svg/solar_file-send-bold.svg',
      icon: '/assets/images/dashboard/icons_off/svg/solar_file-send-bold.svg',
      isDropdown: false,
      isActive: false,
      link: routeMap.applicants.list,
      subMenuItems: [],
    },
    {
      id: 4,
      title: 'Administrateurs',
      icon_active:
        '/assets/images/dashboard/icons_active/svg/gala_settings.svg',
      icon: '/assets/images/dashboard/icons_off/svg/gala_settings.svg',
      isDropdown: false,
      isActive: false,
      link: routeMap.admins.list,
      subMenuItems: [],
    },
    {
      id: 5,
      title: 'Référentiel',
      icon_active:
        '/assets/images/dashboard/icons_active/svg/codicon_group-by-ref-type.svg',
      icon: '/assets/images/dashboard/icons_off/svg/codicon_group-by-ref-type.svg',
      isDropdown: false,
      isActive: false,
      link: routeMap.referential.home,
      subMenuItems: [],
    },
    {
      id: 6,
      title: 'Historiques',
      icon_active:
        '/assets/images/dashboard/icons_active/svg/solar_history-2-linear.svg',
      icon: '/assets/images/dashboard/icons_off/svg/solar_history-2-linear.svg',
      isDropdown: false,
      isActive: false,
      link: routeMap.histories,
      subMenuItems: [],
    },
    {
      id: 7,
      title: 'Profil',
      icon_active:
        '/assets/images/dashboard/icons_active/svg/fluent_person-16-filled.svg',
      icon: '/assets/images/dashboard/icons_off/svg/fluent_person-16-filled.svg',
      isDropdown: false,
      isActive: false,
      link: routeMap.adminProfil,
      subMenuItems: [],
    },
  ];
  pictureUrl: string | any = 'https://ui-avatars.com/api/?name=';

  administrator!: IAdministrator;
  isOpenNavbar: boolean = false;
  isPageChange: boolean = false;
  constructor(
    private _router: Router,
    private _utilService: UtilsService,
    private _authService: AuthService
  ) {
    this.getAdminData();

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isPageChange = true;
      } else if (event instanceof NavigationEnd) {
        this.isPageChange = false;
      }
    });
  }
  toggleNavbar() {
    this.isOpenNavbar = !this.isOpenNavbar;
  }

  getAdminData() {
    this._authService.profile$.subscribe((response) => {
      this.administrator = response.data.user;
    });
  }

  handleMenuChange(event: any) {
    const activeMenuId = event;
    this.setMenuActiveById(activeMenuId);
  }

  setMenuActiveById(menuId: number) {
    this.MENU_LINKS.forEach((menu) => {
      menu.isActive = menu.id === menuId;
    });
  }
  goParameter() {}

  logout() {
    this._utilService.Disconnect();
  }

  onRedirectTo(menu: any) {
    console.log(menu);
    this.MENU_LINKS = this.MENU_LINKS.map((value) => {
      return {
        ...value,
        isActive: value.title == menu.title,
        isOpen: value.title == menu.title && !value.isOpen,
      };
    });

    if (menu.link) {
      this._router.navigate([menu.link]);
    } else if (menu.isDropdown && menu.subMenuItems.length > 0) {
      // this._router.navigate([menu.nzSubmenuItem[0].pathRedirect]);
    }
  }
}
