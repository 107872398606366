<div class="flex items-center justify-between">
  <div class="uppercase text-xl font-bold">
    <nz-breadcrumb [nzAutoGenerate]="true"> </nz-breadcrumb>
  </div>
  <div
    class="flex items-center gap-1 hover:cursor-pointer"
    (click)="onLogout()"
  >
    <span nz-icon nzType="logout" nzTheme="outline"></span>
    <span class="font-bold">Déconnexion</span>
  </div>
</div>
