export enum API_RESPONSE_STATUS {
  SUCCESS = 200,
  SUCCESS_2000 = 2000,
  NOT_FOUND = 4000,
  INVALID_DATA = 422,
  PASSWORD_NOT_MATCH = 505,
  ALREADY_EXIST = 404,
}

export const MANAGER_ACCOUNT_TYPE = [
  {
    label: 'GERANCE LIBRE',
    value: 'FREE_MANAGEMENT',
  },
  {
    label: 'GERANCE DIRECT',
    value: 'DIRECT_MANAGEMENT',
  },
];
